<template>
    <div class="activity-schedule">
        <div class="header-wrapper">
            <div class="return_page">
                <VigikBreadCrumb />
            </div>

            <div class="top-block">
                <h1 class="title">
                    {{ $route.params.providerName }}
                </h1>
                <BasicChip
                    :text="
                        isPersonnalised === '0'
                            ? $t('vigik.global.default')
                            : isPersonnalised === '1'
                            ? $t('vigik.global.whitelisted', { count: 0 })
                            : isPersonnalised === '2'
                            ? $t('vigik.global.blacklisted', { count: 0 })
                            : null
                    "
                    :pinColor="
                        isPersonnalised === '0'
                            ? 'blue-teal'
                            : isPersonnalised === '1'
                            ? 'orange'
                            : isPersonnalised === '2'
                            ? 'blue'
                            : null
                    "
                />
            </div>
            <div class="activity-infos-container">
                <div
                    v-for="(activity, index) in activitiesInfos.slice(0, 4)"
                    :key="index"
                    class="activity-infos-wrapper"
                >
                    <p>{{ activity.label }}</p>
                    <BasicChip
                        :text="
                            activity.allow
                                ? $t('global.modal.providersStatus.authorized')
                                : $t(
                                      'global.modal.providersStatus.noAuthorized'
                                  )
                        "
                        :background="
                            activity.allow ? 'blue-powder' : 'dark-blue'
                        "
                    />
                </div>
                <div
                    v-if="activitiesInfos.length > 4"
                    class="activity-infos-see-more"
                    @click="seeMoreActivitiesInfos"
                >
                    <i class="fas fa-plus"></i>
                </div>
            </div>
        </div>
        <week-calendar
            v-if="display"
            :readonly="false"
            :isPersonnalised="isPersonnalised"
            :days="days"
            :maxTimeRange="10"
            :maxDifferentDay="7"
            :initialValue="initialValue"
            :isDeepEqual="isDeepEqual"
            :scheduleId="scheduleId"
            :isReset="isReset"
            @change="updateValue"
            @update:initialDisplayArray="updateInitialDisplayArray"
            @razVerif="razVerif"
            @saveVerif="saveVerif"
            @cancelVerif="cancelVerif"
        />
        <div class="center" v-else>
            <Loader />
        </div>
    </div>
</template>

<script>
import WeekCalendar from "@/components/basic/WeekCalendar"
import BasicChip from "../../components/basic/BasicChip.vue"
import {
    getProviderSchedule,
    saveProviderSchedule,
    resetProviderSchedule,
    getActivitiesInfosProviders,
} from "@/services/intratone/accesstype"
import Loader from "@/components/basic/Loader"
import { useToast } from "vue-toastification"
import VigikBreadCrumb from "@/components/entities/VigikBreadCrumb.vue"

const toast = useToast()

import { deepEqual } from "@/helpers/commonFunctions"

export default {
    name: "ProviderSchedule",
    components: {
        Loader,
        WeekCalendar,
        BasicChip,
        VigikBreadCrumb,
    },
    data() {
        return {
            days: [
                { value: 0, name: "weekType.weekday.monday" },
                { value: 1, name: "weekType.weekday.tuesday" },
                { value: 2, name: "weekType.weekday.wednesday" },
                { value: 3, name: "weekType.weekday.thursday" },
                { value: 4, name: "weekType.weekday.friday" },
                { value: 5, name: "weekType.weekday.saturday" },
                { value: 6, name: "weekType.weekday.sunday" },
            ],
            options: [
                {
                    value: "0",
                    name: this.$t("vigik.global.default"),
                    pinColor: "blue",
                },
                {
                    value: "1",
                    name: this.$t("vigik.global.whitelisted", {
                        count: 0,
                    }),
                    pinColor: "orange",
                },
                {
                    value: "2",
                    name: this.$t("vigik.global.blacklisted", {
                        count: 0,
                    }),
                    pinColor: "blue-teal",
                },
            ],
            initialValue: [],
            display: false,
            displayArray: [],
            initialDisplayArray: [],
            activitiesInfos: [],
            isPersonnalised: this.$route.params.isPersonnalised,
            changementStatus: this.$route.params.isPersonnalised,
            scheduleId: this.$route.params.scheduleId,
            isReset: false, // if current schedule is the original one
            resetRequestInProgress: false, // if it's during a reset request
            isExiting: false,
        }
    },
    created() {
        getActivitiesInfosProviders(
            this.$route.params.accessId,
            this.$route.params.providerId
        ).then((response) => {
            this.activitiesInfos = response.data.list
        })
    },
    mounted() {
        this.getData()
    },
    computed: {
        /**
         * user have make no change
         * @proposition: rename this function in "scheduleHasBeenUpdated"
         * @returns {false|this is string[]|boolean}
         */
        isDeepEqual() {
            return deepEqual(this.displayArray, this.initialDisplayArray)
        },
    },
    methods: {
        seeMoreActivitiesInfos() {
            this.openModal("providers-status", {
                title: "providersStatus.activities",
                activitiesInfos: this.activitiesInfos,
            })
        },
        async getData() {
            this.display = false
            await getProviderSchedule(
                this.$route.params.accessId,
                this.$route.params.providerId
            ).then((response) => {
                this.initialValue = response.data.days
                this.display = true
            })
        },
        goBack() {
            this.$router.go(-1)
        },
        /**
         * if schedule has changed
         * @param payload
         */
        updateValue(payload) {
            // define data to send to ApiData
            this.displayArray = payload.periods
            // if schedule has been changed
            if (!this.isDeepEqual) {
                this.isReset = false
            } else {
                // if there are reset request in progress
                if (this.resetRequestInProgress) this.isReset = true
            }
        },
        updateInitialDisplayArray(payload) {
            this.initialDisplayArray = payload.periods
        },
        async saveVerif(value, needGoBack = true) {
            if (!this.isReset) {
                await this.save()
            } else {
                await resetProviderSchedule(
                    this.$route.params.accessId,
                    this.$route.params.providerId
                )
            }

            toast.success(
                this.$t("vigik.schedule.update-schedule", {
                    name: this.$route.params.providerName,
                }),
                {
                    icon: "fas fa-check",
                }
            )
            this.isExiting = value

            if (needGoBack) {
                this.goBack()
            }
        },
        cancelVerif() {
            this.getData()
        },
        async save() {
            switch (this.changementStatus) {
                case "0" && this.isPersonnalised !== "0":
                    return await saveProviderSchedule(
                        this.$route.params.accessId,
                        this.$route.params.providerId,
                        {
                            mode: "full",
                            days: this.displayArray,
                        }
                    )
                case "1":
                    break
                case "2":
                    break
            }
            await saveProviderSchedule(
                this.$route.params.accessId,
                this.$route.params.providerId,
                {
                    mode: "full",
                    days: this.displayArray,
                }
            )
        },
        async razVerif() {
            this.display = false
            await resetProviderSchedule(
                this.$route.params.accessId,
                this.$route.params.providerId
            )
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.$router.go(-1)
                    this.display = true
                })
        },
    },
    watch: {
        $route() {
            this.getData()
        },
    },
    beforeRouteLeave(to, from, next) {
        if (this.isExiting || this.isDeepEqual) {
            next()
        } else {
            this.openModal("validation", {
                title: "valid.edit",
                valid: () => {
                    this.saveVerif(false, false)
                    next()
                },
                refuse: () => {
                    next(false)
                },
            })
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";

.btn-cancel {
    @media all and (max-width: 768px) {
        display: none;
    }
}

.btn-save {
    @media all and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        position: absolute;
        top: 85%;
        left: 85%;
        background-color: $orange-neutral;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        font-size: $big;
        color: $white;
        cursor: pointer;
        z-index: 10;
        .text {
            display: none;
        }
        .icon {
            display: block;
        }
    }
    @media all and (min-width: 768px) {
        .text {
            display: block;
        }
        .icon {
            display: none;
        }
    }
}

.btn-raz {
    @media all and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        position: absolute;
        top: 78%;
        left: 85%;
        background-color: $blue-neutral;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        font-size: $big;
        color: $white;
        cursor: pointer;
        z-index: 10;
        .text {
            display: none;
        }
        .icon {
            display: block;
        }
    }
    @media all and (min-width: 768px) {
        .text {
            display: block;
        }
        .icon {
            display: none;
        }
    }
}
@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}

.activity-schedule {
    width: 100vw;
    height: 100%;
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 3em;

    @media all and (max-width: 768px) {
        padding: 10px 20px 0px 20px;
    }
    .header-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        .center {
            width: 100%;
            height: 200px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            align-items: center;
        }
        .return_page {
            a {
                color: $blue-darker;
                cursor: pointer;
                text-decoration: none;
                transition: color 200ms;
                font-family: "Avenir Heavy";
                font-size: 12px;
                i {
                    margin-right: 10px;
                    transition: margin 200ms;
                }
            }
            a:hover {
                color: $orange-neutral;
                i {
                    margin-right: 15px;
                }
            }
        }
        .button-block {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;
            align-items: center;
            flex-wrap: nowrap;
            gap: 20px;
            margin: 20px;
            padding-left: 150px;
            .btn-style {
                padding: 20px;
            }
        }
        .activity-infos-container {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;
            align-items: center;

            .activity-infos-wrapper {
                display: flex;
                align-items: center;
                gap: 0.5em;

                p {
                    margin: 0;
                    font-size: $small;
                }
            }

            .activity-infos-see-more {
                display: flex;
                align-items: center;
                background: $blue-powder;
                padding: 0.7em;
                border-radius: 15px;
                &:hover {
                    cursor: pointer;
                }

                i {
                    font-size: $verySmall;
                }
            }
        }
        .top-block {
            display: flex;
            gap: 1em;
            align-items: center;
            flex-wrap: wrap;
            .title {
                font-family: $font_avenir_black;
                margin: 0;
                font-size: $veryBig;
            }
        }
    }
}
</style>
